@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "ABCGravity"; /*Can be any text*/
  src: local("ABCGravity"), url("./fonts/ABCGravity-Normal-Trial.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "ABCGravity"; /*Can be any text*/
  src: local("ABCGravity"), url("./fonts/ABCGravity-Compressed-Trial.otf") format("opentype");
  font-weight: compressed;
}

@font-face {
  font-family: "ABCGravity"; /*Can be any text*/
  src: local("ABCGravity"), url("./fonts/ABCGravity-XCompressed-Trial.otf") format("opentype");
  font-weight: xcompressed;
}

@font-face {
  font-family: "ABCGravity"; /*Can be any text*/
  src: local("ABCGravity"), url("./fonts/ABCGravity-SemiCondensed-Trial.otf") format("opentype");
  font-weight: semi-condensed;
}

@font-face {
  font-family: "ABCGravity"; /*Can be any text*/
  src: local("ABCGravity"), url("./fonts/ABCGravity-ExtraCondensed-Trial.otf") format("opentype");
  font-weight: extra-condensed;
}

@font-face {
  font-family: "MinionPro"; /*Can be any text*/
  src: local("MinionPro"), url("./fonts/MinionPro-Regular.otf") format("opentype");
  font-weight: regular;
}
